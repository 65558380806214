import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';

const ResumePage = () => (
  <Layout>
    <StaticQuery
      query={graphql`
        {
          allContentfulAsset(filter: { title: { eq: "Resume" } }) {
            edges {
              node {
                file {
                  url
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const resume = data.allContentfulAsset.edges[0].node.file.url;
        if (typeof window !== 'undefined') {
          window.location.href = `https://${resume}`;
        }
        return <div />;
      }}
    />
  </Layout>
);

export default ResumePage;
